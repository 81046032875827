import { apolloClient } from '../apollo-client'
import {
  FormBuilderLoginDocument,
  FormBuilderLoginMutation,
  FormBuilderLoginMutationVariables,
} from '../graphql-schema'
import { setAccessToken } from '../providers/access-token'

export async function formBuilderLogin(values: FormBuilderLoginMutationVariables) {
  const { data } = await apolloClient.mutate<FormBuilderLoginMutation, FormBuilderLoginMutationVariables>({
    mutation: FormBuilderLoginDocument,
    variables: { ...values },
  })
  const accessToken = data?.formBuilderLogin?.accessToken
  if (accessToken) {
    setAccessToken(accessToken)
  }
}
