import { Formik } from 'formik'
import { navigate } from 'gatsby'
import React from 'react'
import { formBuilderLogin } from 'src/backend/form-builder-login'
import { Layout } from 'src/components/layout'
import { hasExpired } from 'src/providers/access-token'

const initialValues = { email: '', password: '' } as const

export default function IndexPage() {
  if (!hasExpired()) {
    navigate('/designs')
    return null
  }
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center h-screen space-y-8">
        <h1 className="text-2xl font-bold text-center">Lobis Business</h1>
        <div className="space-y-4">
          <Formik
            initialValues={initialValues}
            validate={values => {
              const errors: any = {}
              if (!values.email) {
                errors.email = 'Required'
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Invalid email address'
              }
              return errors
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                await formBuilderLogin({ data: values })
                navigate('/designs')
              } catch (error) {
                setErrors({ email: 'Invalid email or password' })
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                <div className="space-y-2">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    className="text-input"
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="text-input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                </div>
                <button className="btn" type="submit" disabled={isSubmitting}>
                  Login
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  )
}
